import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/analytics'

// firebase init goes here
const config = {
  apiKey: "AIzaSyB0aYxC8nW6feTIFCLMhgGfyALLpUqY_eg",
  authDomain: "ttsapp-366314.firebaseapp.com",
  projectId: "ttsapp-366314",
  storageBucket: "ttsapp-366314.appspot.com",
  messagingSenderId: "931989048501",
  appId: "1:931989048501:web:62ae7008a8fe1375e182fc",
  measurementId: "G-R1G8PFD9RG"
}

firebase.initializeApp(config)
const analytics = firebase.analytics()

// firebase utils
const db = firebase.firestore()
const auth = firebase.auth()
const functions = firebase.functions()
const storage = firebase.storage()
const currentUser = auth.currentUser
const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider()
const githubAuthProvider = new firebase.auth.GithubAuthProvider()

if (location.hostname === "localhost") {
  db.useEmulator("localhost", 8080)
  auth.useEmulator("http://localhost:9099")
  functions.useEmulator("localhost",5001)
  storage.useEmulator("localhost",9199)
}

export {
  firebase,
  db,
  auth,
  currentUser,
  functions,
  storage,
  googleAuthProvider,
  twitterAuthProvider,
  githubAuthProvider,
  analytics
}
