import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

const fb = require('./firebaseConfig.js')
const config = require('./config.js')

Vue.use(VueAxios, axios)
Vue.use(Vuex)

// handle page reload
fb.auth.onAuthStateChanged(user => {
    console.debug('User State Changed: ' + JSON.stringify(user))
    if (user) {
        store.commit('setCurrentUser', user)
        store.dispatch('includeStripe')
    }
})

// handle id token refresh
fb.auth.onIdTokenChanged(user => {
    if (user) {
        store.commit('setCurrentUser', user)
        user.getIdToken(false).then(idToken => {
            console.debug('User Id Token Changed: ' + JSON.stringify(idToken))
            store.commit('setCurrentUserIdToken', idToken)
        })
    }
})

export const store = new Vuex.Store({
    state: {
      currentUser: null,
      currentUserIdToken: null,
      stripeAPIKey: config.CONSTANTS.STRIPE_PUBLIC_KEY,
      stripe: null
    },
    actions: {
        clearData ({ commit }) {
            commit('setCurrentUser', null)
            commit('setCurrentUserIdToken', null)
        },
        includeStripe ({ commit, state }) {
            let documentTag = document
            let tag = 'script'
            let object = documentTag.createElement(tag)
            let scriptTag = documentTag.getElementsByTagName(tag)[0]
            object.src = '//js.stripe.com/v3/'
            object.addEventListener('load', () => {
              // eslint-disable-next-line no-undef
              var stripe = Stripe(state.stripeAPIKey)
              commit('setStripe', stripe)
            }, false)
            scriptTag.parentNode.insertBefore(object, scriptTag)
        }
    },
    mutations: {
        setCurrentUser (state, val) {
          state.currentUser = val
        },
        setCurrentUserIdToken (state, val) {
          state.currentUserIdToken = val
        },
        setStripe (state, val) {
          state.stripe = val
        }
    }
})