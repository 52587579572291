<template>
    <v-row>
        <v-col>
            <VuetifyAudio :file="audioURL" color="primary" downloadable autoPlay flat></VuetifyAudio>
        </v-col>
    </v-row>
</template>

<script>

export default {
    components: {
        VuetifyAudio: () => import('vuetify-audio')
    },
    props: ["audioURL"]
}
</script>