<template>
  <v-app>
    <v-app-bar
      dense
      elevation="3"
      app
      flat
      color="#efefef"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Highku Text-To-Speech</v-toolbar-title>
      <v-spacer></v-spacer>
      
      <v-chip 
        label
        small
      >
          Available Credits: {{userSnapshot ? Math.round(userSnapshot.creditsRemaining * 10000) : 0}} chars
      </v-chip>
      <span class="pl-2">
        <BuyCreditsButton></BuyCreditsButton>
      </span>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      color="grey lighten-3"
      :mini-variant="mini"
      temporary 
      app     
    >
      <v-list-item class="px-2">
        <v-list-item-avatar size=36 rounded>
          <v-img :src="currentUser.photoURL ? currentUser.photoURL : 'https://randomuser.me/api/portraits/men/85.jpg'"></v-img>
        </v-list-item-avatar>
        
        <v-list-item-title>{{ currentUser.displayName }}</v-list-item-title>
      </v-list-item>
      
      <v-divider class="mt-0 mb-0"></v-divider>
      
      <v-list>
        <v-list-item
          v-for="item in mainNavItems"
          :key="item.title"
          :to="item.targetRoute"
          style="text-decoration: none; color: inherit;"
        >
          <v-list-item-icon>
            <v-icon :title="item.title">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon title="Logout">mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-main class="pt-0">
      <v-container fluid>
        <KeepAlive :max="2">
          <router-view :userSnapshot="userSnapshot"/>
        </KeepAlive>
      </v-container>
    </v-main>
    
    <v-footer >
      <v-row justify="end">
        <v-col cols="auto">
          {{ new Date().getFullYear() }} — <strong>HKU</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import BuyCreditsButton from "../../components/app/BuyCreditsButton.vue"

const fb = require('../../firebaseConfig.js')

export default {
  components: {
    BuyCreditsButton
  },
  computed: {
    ...mapState(['currentUser'])
  },
  data () {
    return {
      drawer: false,
      mini:false,
      mainNavItems: [
        { title: 'Home', icon: 'mdi-home-city', targetRoute: {name: 'home'} },
        // { title: 'My Exports', icon: 'mdi-cloud-download-outline', targetRoute: {name: 'myexports'} },
      ],
      userSnapshot: null,
      userDocListenerUnsub: null
    }
  },
  methods: {
    logout () {
      fb.auth.signOut().then(() => {
        this.$store.dispatch('clearData')
        this.$router.push(
          {
            name: "login"
          }
        )
      }).catch(err => {
        console.error(err)
      })
    },
    listenToUserSnapshot ()  {
      this.userDocListenerUnsub = fb.db.doc("/users/"+this.currentUser.uid).onSnapshot((snap) => {
        this.userSnapshot = snap.data();
      })
    }
  },
  mounted () {
    this.listenToUserSnapshot();
  },
  beforeUnmount () {
    this.userDocListenerUnsub();
  }
  //
};
</script>