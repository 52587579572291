<template>
  <v-card-text>
    <v-row justify="center" align="center">
      <v-col cols="11">
        <SocialSignInButton
          :buttonText="googleButtonText"
          buttonIcon="mdi-google"
          provider="googleAuthProvider"
          @loginSuccessful="loginSuccessful"
        ></SocialSignInButton>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="11">
        <SocialSignInButton
          :buttonText="githubButtonText"
          buttonIcon="mdi-github"
          provider="githubAuthProvider"
          @loginSuccessful="loginSuccessful"
        ></SocialSignInButton>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
const config = require("../../config.js");
const fb = require("../../firebaseConfig.js");

import SocialSignInButton from "../../components/auth/SocialSignInButton";

export default {
  components: {
    SocialSignInButton
  },
  data() {
    return {
      errorMsg: "",
    };
  },
  computed: {
    pageType: function () {
      return this.$route.name;
    },
    googleButtonText: function () {
      if (this.$route.name === "login") {
        return "Sign in with Google";
      } else {
        return "Sign up with Google";
      }
    },
    githubButtonText: function () {
      if (this.$route.name === "login") {
        return "Sign in with Github";
      } else {
        return "Sign up with Github";
      }
    }
  },
  methods: {
    loginSuccessful(userCredential, isNewUser) {
      console.debug("User:" + JSON.stringify(userCredential.user));
      console.debug("Is new user: " + isNewUser);
      if (isNewUser) this._createUserDoc(userCredential.user);
      this.$store.commit("setCurrentUser", userCredential.user);
      if (userCredential.user.email && !userCredential.user.emailVerified) {
        var actionCodeSettings = {
          url: config.CONSTANTS.APPLICATION_WEB_URL + "/dashboard",
        };
        userCredential.user
          .sendEmailVerification(actionCodeSettings)
          .then(() => {
            // Email sent.
            this.$router.push("/dashboard");
          })
          .catch(function (error) {
            alert("Error while sending verification email." + error.message);
          });
      } else {
        this.$router.push("/dashboard");
      }
      console.debug("Sign up done.");
    },
    showErrorAlert() {
      this.$toasted.error(this.errorMsg);
      console.debug(this.errorMsg);
    },
    _createUserDoc(user) {
      fb.db.collection('/users/').doc(user.uid).set({
        createdAt: fb.firebase.firestore.FieldValue.serverTimestamp(),
        creditsRemaining: 2,
        subscriptionPlan: 'free'
      })
    }
  },
  mounted() {},
};
</script>