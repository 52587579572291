<template>
  <div>
    <v-row justify="center" class="mt-5">
      <v-col xl="8" lg="8" md="10" sm="12" cols="12">
        <v-row>
          <v-col xl="8" lg="8" md="12" sm="12" cols="12">
            <SpeechInputBox v-model="textInput"></SpeechInputBox>
            <!-- <v-textarea 
              label="Text" 
              counter 
              placeholder="Enter your text here." 
              rows="15" 
              outlined 
              v-model="textInput" 
              :error-messages="textInputErrorMsgs"
            >
            </v-textarea> -->
          </v-col>
          <v-col xl="4" lg="4" md="12" sm="12" cols="12" class="mt-2">
            <VoiceSelecter @voiceSelected="setVoiceSelection"></VoiceSelecter>
            <v-row>
              <v-col>
                <v-btn 
                  block 
                  dense 
                  @click="convert" 
                  color="primary" 
                  :disabled="isConvertButtonDisabled"
                  :loading="loading"
                >
                    {{ convertButtonText }}
                </v-btn>
              </v-col>
            </v-row>
            <AudioPlayer :audioURL="audioURL"></AudioPlayer>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AudioPlayer from "../../components/app/AudioPlayer.vue"
import VoiceSelecter from "../../components/app/VoiceSelecter.vue"
import SpeechInputBox from "../../components/app/SpeechInputBox.vue"

const fb = require("../../firebaseConfig.js");

export default {
  props: ["userSnapshot"],
  components: {
    AudioPlayer,
    VoiceSelecter,
    SpeechInputBox
  },
  data() {
    return {
      audioURL: null,
      textInput: null,
      selectedVoice: null,
      selectedLanguage: null,
      convertButtonText: "Convert Text To Speech",
      textInputErrorMsgs: [],
      loading: false
    };
  },
  watch: {
    textInput: function (newVal, oldVal) {
      if (oldVal == newVal) return;
    },
    selectedVoice: function (newVal, oldVal) {
      if (oldVal == newVal) return;
    }
  },
  computed: {
    ...mapState(["currentUser"]),
    isConvertButtonDisabled() {
      console.log(JSON.stringify(this.textInput));
      return (
        this.selectedVoice == null || 
        this.textInput == null ||
        this.userSnapshot.creditsRemaining * 10000 < this.textInput.length
      )
    }
  },
  methods: {
    convert() {
      console.log("Text: " + JSON.stringify(this.textInput));
      this.loading = true;
      console.log("Submitting.");
      fb.functions
      .httpsCallable("tts-getAudioForText", { timeout: 60000 })({
        text: JSON.stringify(this.textInput),
        voice: this.selectedVoice,
        language: this.selectedLanguage
      })
      .then((result) => {
        console.log(result)
        this.audioURL = null;
        fb.storage.ref(this.currentUser.uid+"/audio/"+result.data).getDownloadURL().then((downloadURL) => {
          this.loading = false;
          console.log("Download URL: " + downloadURL)
          this.audioURL = downloadURL;
        });
      }).catch((e) => {
        console.log("Error");
        console.log(e);
        this.loading = false;
      })
    },
    setVoiceSelection(value, language) {
      console.log("Set voice selection: " + value + ", " + language)
      this.selectedVoice = value;
      this.selectedLanguage = language;
    }
  },
  mounted() {
    
  }
}
</script>

<style lang="css" scoped>
/* .row-pointer >>> tbody tr :hover {
  cursor: pointer;
} */
</style>