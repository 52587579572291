<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col cols="12">
                    <v-select :items="languages"
                        label="Language"
                        v-model="selectedLanguage"
                        dense>
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-select :items="genders"
                        label="Gender"
                        v-model="selectedGender"
                        dense>
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10">
                    <v-select :items="voices"
                        label="Voice"
                        v-model="selectedVoice"
                        dense>
                    </v-select>
                </v-col>
                <v-col cols="2">
                    <span class="text-button"><a @click="playVoiceSample" :disabled="selectedVoice == null"><v-icon title="Play sample" color="accent">mdi-volume-high</v-icon></a></span>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>

const fb = require("../../firebaseConfig.js");

export default {
    data () {
        return {
            languages: [
                {
                    text: "English (US)",
                    value: "en-US"
                },
                {
                    text: "English (UK)",
                    value: "en-GB",

                },
                {
                    text: "English (India)",
                    value: "en-IN"
                },
                {
                    text: "English (AU)",
                    value: "en-AU"
                },
                {
                    text: "Danish (Denmark)",
                    value: "da-DK"
                },
                {
                    text: "Dutch (Belgium)",
                    value: "nl-BE"
                },
                {
                    text: "Dutch (Netherlands)",
                    value: "nl-NL"
                },
                {
                    text: "French (Canada)",
                    value: "fr-CA"
                },
                {
                    text: "French (France)",
                    value: "fr-FR"
                },
                {
                    text: "German (Germany)",
                    value: "de-DE"
                },
                {
                    text: "Greek (Greece)",
                    value: "el-GR"
                },
                {
                    text: "Hindi (India)",
                    value: "hi-IN"
                },
                {
                    text: "Indonesian (Indonesia)",
                    value: "id-ID"
                },
                {
                    text: "Italian (Italy)",
                    value: "it-IT"
                },
                {
                    text: "Japanese (Japan)",
                    value: "ja-JP"
                },
                {
                    text: "Korean (South Korea)",
                    value: "ko-KR"
                },
                {
                    text: "Malay (Maylaysia)",
                    value: "ms-MY"
                },
                {
                    text: "Mandarin Chinese (China)",
                    value: "cmn-CN"
                },
                {
                    text: "Mandarin Chinese (Taiwan)",
                    value: "cmn-TW"
                },
                {
                    text: "Norwegian (Norway)",
                    value: "nb-NO"
                },
                {
                    text: "Polish (Poland)",
                    value: "pl-PL"
                },
                {
                    text: "Portuguese (Brazil)",
                    value: "pt-BR"
                },
                {
                    text: "Portuguese (Portugal)",
                    value: "pt-PT"
                },
                {
                    text: "Romanian (Romania)",
                    value: "ro-RO"
                },
                {
                    text: "Spanish (Spain)",
                    value: "es-ES"
                },
                {
                    text: "Spanish (US)",
                    value: "es-US"
                },
                {
                    text: "Swedish (Sweden)",
                    value: "sv-SE"
                },
                {
                    text: "Turkish (Turkey)",
                    value: "tr-TR"
                },
                {
                    text: "Ukrainian (Ukraine)",
                    value: "uk-UA"
                },
                {
                    text: "Vietnamese (Vietname)",
                    value: "vi-VN"
                }
            ],
            genders: [
                {
                    text: "Male",
                    value: "MALE"
                },
                {
                    text: "Female",
                    value: "FEMALE"
                }
            ],
            voices: [],
            selectedLanguage: "en-US",
            selectedGender: "FEMALE",
            selectedVoice: null
        }
    },
    watch: {
        selectedLanguage: function () {
            this.getVoiceList();
        },
        selectedGender: function () {
            this.getVoiceList();
        },
        selectedVoice: function (newVal, oldVal) {
            oldVal;
            this.$emit("voiceSelected", newVal, this.selectedLanguage);
        }
    },
    methods: {
        getVoiceList () {
            fb.functions.httpsCallable("tts-getVoices", { timeout: 60000 })({
                language: this.selectedLanguage,
                gender: this.selectedGender
            })
            .then((result) => {
                this.voices = result.data
                this.selectedVoice = this.voices[0].value;
            })
        },
        playVoiceSample () {
            var url = "https://cloud.google.com/static/text-to-speech/docs/audio/" + this.selectedVoice + ".wav";
            const audio = new Audio(url);
            audio.play();
        }
    },
    computed: {
    },
    mounted () {
        this.getVoiceList();
    }
}
</script>