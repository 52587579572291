import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase'

import BaseTemplate from '@/pages/app/BaseTemplate'
import Home from '@/pages/app/Home'
import UserAuthTemplate from '@/pages/auth/UserAuthTemplate'
import LoginSignupPage from '@/pages/auth/LoginSignupPage'

// register the plugin on vue
import Toasted from 'vue-toasted';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
      {
        path: '*',
        redirect: '/app'
      },
      {
        path: '/auth',
        component: UserAuthTemplate,
        children: [
          {
            path: '',
            redirect: 'login'
          },
          {
            path: 'login',
            name: 'login',
            component: LoginSignupPage
          },
          {
            path: 'signup',
            name: 'signup',
            component: LoginSignupPage
          }
        ]
      },
      {
        path: '/app',
        component: BaseTemplate,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            redirect: 'home'
          },
          {
            path: 'home',
            name: 'home',
            component: Home
          }
        ]
      }
    ]
})

Vue.use(Toasted, {router, position:'bottom-left', duration: 5000, iconPack : 'mdi'})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    const currentUser = firebase.auth().currentUser
  
    if (requiresAuth && !currentUser) {
      next('/auth/login')
    } else if (!requiresAuth && currentUser) {
      next('/app')
    } else if (requiresAuth && currentUser) {
      next()
    } else {
      next()
    }
  })
  
  export default router  