<template>
    <v-btn 
        small 
        link 
        elevation="0" 
        color="primary" 
        @click="initiateCheckoutSession"
        :loading="loading"
    >
        Buy Credits
    </v-btn>
</template>

<script>
import { mapState } from 'vuex'
const config = require('../../config.js')
const fb = require("../../firebaseConfig.js");

export default {
  props: ['redirectTo'],
  data () {
    return {
      stripeAPIKey: config.CONSTANTS.STRIPE_PUBLIC_KEY,
      loading: false
    }
  },
  computed: {
    ...mapState(['currentUserIdToken', 'stripe'])
  },
  methods: {
    initiateCheckoutSession () {
      this.loading = true
      
      fb.functions
      .httpsCallable("stripe-createCheckoutSession", { timeout: 60000 })({
        redirectTo: this.redirectTo ? this.redirectTo : 'app/home'
      })
      .then((result) => {
        console.log(result)
        this.stripe.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as argument here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: result.data.id
        }).then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          this.loading = false
          this.$toasted.error(result.error.message);        
        })
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
