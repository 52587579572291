<template>
  <div>
    <v-row>
      <v-col>
        <v-btn color="primary" :loading="performingRequest" block @click="initSignIn">
          <v-icon left>{{buttonIcon}}</v-icon> {{buttonText}}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const fb = require("../../firebaseConfig.js");

export default {
  props: ['buttonText', 'buttonIcon', 'provider'],
  data() {
    return {
      performingRequest: false,
    };
  },
  methods: {
    initSignIn() {
      this.performingRequest = true;
      fb.auth
        .signInWithPopup(fb[this.provider])
        .then((userCredential) => {
          var isNewUser = userCredential.additionalUserInfo.isNewUser
          this.$emit("loginSuccessful", userCredential, isNewUser);
        })
        .catch((err) => {
          console.log(err);
          this.performingRequest = false;
          if (err.code === "auth/multi-factor-auth-required") {
            // The user is a multi-factor user. Second factor challenge is required.
            this.$emit("mfaAuthRequired", err.resolver);
          } else if (err.code === "auth/popup-blocked") {
            this.errorMsg =
              "The Google Sign-in popup was blocked by your browser. Please enable popups for this site and try again.";
            this.showErrorAlert();
          } else if (err.code === "auth/user-disabled") {
            this.errorMsg = "Access denied. User account is disabled.";
            this.showErrorAlert();
          } else if (err.code === "auth/invalid-email") {
            this.errorMsg = "Invalid email address. Please try again.";
            this.showErrorAlert();
          } else if (
            err.code === "auth/account-exists-with-different-credential"
          ) {
            fb.auth.fetchSignInMethodsForEmail(err.email).then((methods) => {
              console.log(methods);
              this.errorMsg = "Please sign in with " + methods[0] + ".";
              this.showErrorAlert();
            });
          } else {
            this.errorMsg = err.message;
            this.showErrorAlert();
          }
        });
    },
    showErrorAlert() {
      this.$toasted.error(this.errorMsg);
      console.error(this.errorMsg);
    },
  },
};
</script>