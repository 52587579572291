<template>
  <v-row>
    <v-col>
        <bubble-menu
            :editor="editor"
            :tippy-options="{ duration: 100 }"
            v-if="editor"
        >
            <v-btn-toggle
                dense
                background-color="primary"
                dark
                multiple
            >
                <v-btn x-small @click="editor.chain().focus().toggleBold().run()" :input-value="editor.isActive('bold')">
                    <v-icon x-small>mdi-format-bold</v-icon>
                </v-btn>

                <v-btn x-small @click="editor.chain().focus().toggleItalic().run()" :input-value="editor.isActive('italic')">
                    <v-icon x-small>mdi-format-italic</v-icon>
                </v-btn>

                <v-btn x-small @click="editor.chain().focus().toggleUnderline().run()" :input-value="editor.isActive('underline')">
                    <v-icon x-small>mdi-format-underline</v-icon>
                </v-btn>
            </v-btn-toggle>
        </bubble-menu>
        <v-card outlined>
            <v-card-text>
                <editor-content :editor="editor" />
            </v-card-text>
        </v-card>
        <div class="character-count" v-if="editor" align="right">
            <span class="text-caption">{{ editor.storage.characterCount.characters() }} chars </span>
        </div>
    </v-col>
  </v-row>
</template>

<script>
import CharacterCount from '@tiptap/extension-character-count'
import Placeholder from '@tiptap/extension-placeholder'
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";

export default {
  components: {
    EditorContent,
    BubbleMenu
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    limit: {
        type: Number,
        default: 2048
    }
  },
  data() {
    return {
      editor: null,
    };
  },
  watch: {
    value(value) {
      // HTML
    //   const isSame = this.editor.getHTML() === value;

      // JSON
      const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },
  methods: {
    handleInput() {
      console.log("In handleInput");
      this.$emit("input", this.content);
    },
  },
  mounted() {
    this.editor = new Editor({
      content: null,
      extensions: [
        StarterKit,
        Underline,
        CharacterCount.configure({
          limit: this.limit,
        }),
        Placeholder.configure({
          // Use a placeholder:
          placeholder: 'Write something …',
        }),
      ],
      editorProps: {
        attributes: {
          class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl pl-1',
        },
      },
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getJSON());

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  min-height: 400px;
  max-height: 400px;
  overflow: scroll;
  outline: none;

  p {
    margin-bottom: 0 !important;
  }
//   outline: 1px;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

input[type="checkbox"] {
  margin-right: 4px;
}
</style>